import { call } from 'redux-saga/effects';
import axios from 'axios';

const HOST_URL = process.env.API_URL || 'https://sudeca-api.bananacreative.tech/api';
const HOST_MONITORINGURL = process.env.API_MONITORING_URL || 'http://localhost:5002/api';

export function* FetchService(
  url: string,
  method: string = 'GET',
  params: any = undefined,
  auth: string | any = null,
  isImage: boolean = false,
  isMonitoring: boolean = false,
): any {
  const objectRequest: any = {
    method,
    url: `${isMonitoring ? HOST_MONITORINGURL : HOST_URL}${url}`,
    data: params,
    headers: {
      'Content-Type': !isImage ? 'application/json' : 'multipart/form-data',
      Accept: 'application/json',
    },
  };

  if (auth) objectRequest.headers.Authorization = `Bearer ${auth}`;

  const response = yield call(axios, objectRequest);

  const responseBody = isMonitoring ? response : response.data;
  return responseBody;
}
